// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { baseEnvironment } from 'src/environments/baseEnvironment';

export const environment = {
  ...baseEnvironment,
  organizationPrefix: 'BSN',
  organizationUUID: 'a62a52e3-724c-4f4a-957f-e454d80e06e4',
  activeLanguage: 'it-IT',
  tableStructureUUID: '',
  multipleAdminCommunity: true,
  requiredField: {},
  systemToken: '',
  htmlFields: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ header: 1 }, { header: 2 }],
    ['blockquote'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }, 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
    ['clean'],
  ],
  basicConfiguration: {
    htmlEditorMaxLength: {
      active: false,
      where: [
        {
          name: 'product',
          value: 270,
        },
      ],
    },
  },
  googleAPIKey: '',
  manageTemplates: false,
  manageUserFilter: false,

  productEditorTabIcons: ['r', 'z', 'M', 'x', 'm'],
  pwaThemeColor: 'rgba(0, 72, 205, 1.0)',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related message stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an message is thrown.
 */
// import 'zone.js/plugins/zone-message';  // Included with Angular CLI.
